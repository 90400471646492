import '../css/pagination.scss';

export default function Pagination({catClick, prevClick, nextClick}) {

	return(
		<nav className="pagination">
			<ul>
				<li>
					<button onClick={catClick}>
							<span>View categories</span>    
					</button>
				</li>
				<li>
					<button onClick={prevClick}>
							<img src="/images/arrow.svg" alt="" width="189" height="150" />
					</button>   
				</li>
				<li>
					<button onClick={nextClick}>
							<img src="/images/arrow-r.svg" alt="" width="189" height="150" />
					</button>
				</li>
			</ul>
	</nav>
	)
}